.tab-block {
   width: 90%;
   margin: 50px auto;
}

.tab-block .tab-mnu {
   display: block;
   list-style: none;
   padding-left: 0;
   margin: 0;
}

.tab-block .tab-mnu:after {
   content: '';
   display: table;
   clear: both;
}

.tab-block .tab-mnu li {
   box-sizing: border-box;
   float: left;
   background-color:#23395d;
   color: white;
   text-align: center;
   padding: 15px;
   margin-right: 15px;
   cursor: pointer;
   font-weight: 600;
   border-top-style:solid;
   border-top-color:#23395d;
  
}
/*
.tab-block .tab-mnu li:not(:last-child) {
   border-right: 1px solid #4c607c;
}
*/
.tab-block .tab-mnu li:hover:not(.active) {
   background-color: #1890ff;
}

.tab-block .tab-mnu .active {
   background-color: #fff;
   color: #0f4c85;
   border-bottom: 1px solid #eaecec;
   cursor: default;
   font-weight: 800;
}

.tab-block .tab-cont {
   box-sizing: border-box;
   border-top: 1px solid white;
   background-color: white;
   color: #292d2f;
   padding-bottom: 40px;
}

 .tab-block .tab-cont .tab-pane {
   padding: 20px 0px;
  
} 

.parentBox label{
  font-weight: 500;
  font-size: 14px;
}
.row {
   display: flex;
  /* justify-content: center; */


}

.trrow > th{
  border-right:1px solid #e0e0e0 !important;
  border-left:1px solid #e0e0e0 !important;
}
.mainHeading{
  margin-bottom: 1.5rem;
  width:100%;
  font-size: 20px;

  font-weight: 700;
} 

.rpbox{ 
  padding:2rem 1rem;
  display: flex;
  flex-direction: column;
  border:1px solid #e6e3e3;
  border-radius: 0.5rem;
  background-color: white;

}



.col-4 {
   flex: 0 0 33.33%;
   max-width: 33.33%;
}

.colContainer{
  display: flex;
  flex:1
}
.col-3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.col-9 {
   flex: 0 0 65%;
   max-width: 65%;
}

.col-12 {
   flex: 0 0 100%;
   max-width: 100%;  
 
}

.col-6 {
   flex: 0 0 70%;
   max-width: 70%;
}

.col-4,
.col-3,
.col-9,
.col-12,
.col-6 {
   position: relative;
   width: 100%;
   min-height: 1px;
   padding-right: 15px;
   padding-left: 15px;
}

.form-group {
   margin-bottom: 0.5rem;
   display:flex;
   gap: 8px;
   align-items: center;
   font-size: 14px;
   
}

.form-group label {
 
   font-size: 18px;
   color: rgba(0, 0, 0, 0.85);

}

.parentBox{
  padding:2rem;
  background-color:#fcfcfc !important;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-control {
   flex-basis: 50%;
   width: 100%;
   padding: 0.175rem .75rem;
  padding-left: 10px;
   border-top: 0 !important;
   border-left: 0 !important;
   border-right: 0 !important;
   font-size: 12px;
   line-height: 1.6;
   color: rgba(0, 0, 0, 0.85);
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #6d96b4;
   border-radius: 0;
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out ;
   transition: 0.3s;
}

select.form-control:not([size]):not([multiple]) {
   height: calc(1.700rem + -2px);
}

/* h4 {
   font-size: 14px;
   color: white;
   margin-bottom: 5px !important;
   margin-top: 0px !important;
   font-family: inherit;
   font-weight: 400;
   line-height: 1.3;
   display: inline-block;
   background:#23395d;
   padding: 6px;
   border-radius: 4px;
} */

.table-bordered thead td,
.table-bordered thead th {
   border-bottom-width: 1px;
}

.noontable thead th {
   vertical-align: bottom;
   background-color: #003e78;
   color:white;
   /* border-bot: 2px solid #6d96b4 !important; */
   border: 1px solid #6d96b4 !important;
}

.noontable{
  border: 1px solid #6d96b4 !important;
  border-radius: 16px;
}
.table-bordered td,
.table-bordered th {
   border: 1px solid #6d96b4;
}

.table-bordered td {
   padding-top: 5px !important;
   padding-bottom: 5px !important;
 
}

/* .table td, */
/* .table th {
   padding: .75rem;
   vertical-align: top;
   border-top: 1px solid #6d96b4;
   border: 0;
   text-align: left;
   vertical-align: bottom;
   white-space: nowrap;
} */

.table-bordered {
   border: 1px solid #6d96b4;
}

.noontable {
   width: 100%;
   margin-bottom: 1rem;
   background-color: #ffffff;
   
}

table {
   border-collapse: collapse;
}

.table-responsive {
   display: block;
   width: 100%;
   overflow-x: auto;
   -webkit-overflow-scrolling: touch;
   -ms-overflow-style: -ms-autohiding-scrollbar;
}

.border-2 {
   border: 1px solid #e0e0e0;
   padding: 15px;
   margin-bottom: 40px;
   border-radius: 10px;
   margin-top: 20px;
   background-color: white;
   
}

.btn {
   display: inline-block;
   font-weight: 400;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: 1px solid transparent;
   padding: .375rem .75rem;
   font-size: 1rem;
   line-height: 1.5;
   border-radius: .25rem;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-primary {
   color: #fff;
   background-color: #288ba8;
   /*#007bff;*/
   border-color: #007bff;
}

.btn:not(:disabled):not(.disabled) {
   cursor: pointer;
}

.btn-primary.focus,
.btn-primary:focus {
   box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%);
}

.btn.focus,
.btn:focus {
   outline: 0;
   box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.text-center {
   text-align: center;
}
  
  .dis{
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   max-width:90%;
   margin: 10px auto;

  }
  

  .required:after {
content:" *";
color: red;
}
   /* 
   p{
   font-size: 16px;
   }
   .p{
   font-size: 16px;
   text-decoration: none;
   } */

.text{
font-size: 16px;
text-align: right;
}

/* h1::first-letter {
font-size: 200%;
color:#00BFFF;
} */
.fixedspace .form-control {
flex-basis: unset!important;
width: 100%!important;
box-shadow: none;
}

.tableRow > td{
 padding: 8px 16px;
 border-right: 1px solid #e0e0e0;
 border-left: 1px solid #e0e0e0;
 font-weight: 500;
}


/* tr:nth-child(even) {
  background-color: #EAF3F3;
} */

.tableRow{
  border-bottom:1px solid #e0e0e0
}

.fixedspace label {
min-width: 95px;
flex-basis: unset!important;
}

.fixedspace .form-control:focus,.fixedspace .form-control:active,.fixedspace .form-control:focus-within {
outline: none!important;
}

.fixedspace .form-group {
align-items: flex-end;
}

body .fixedspace .w-50 {
width: 50%!important;
}

body .fixedspace .w-40 {
width: 40% !important;
}

body .fixedspace .w-30 {
width: 30% !important;
}

body .fixedspace .w-60 {
width: 60% !important;
}

body .fixedspace .w-45 {
width: 45% !important;
}

body .fixedspace .w-100 {
width: 100% !important;
}

.helpfooter .footercontent {
display: flex;
justify-content: space-between;
align-items: center;
margin: 0 -15px;
}

.helpfooter .footercontent .column {
padding: 0 15px;
}

.helpfooter .footercontent .column.left {
display: inline-flex;
align-items: center;
}

.helpfooter .footercontent .column img {
width: 65px;
margin-right: 15px;
}

.helpfooter {
margin-top: 35px;
border-top: 1px solid #e5e5e5;
padding-top: 10px;
}

.helpfooter .footercontent .column p {
font-size: 14px;
}

.fixedspace select.form-control:not([size]):not([multiple]){
height: 34px;
}

/* Noon */

.ship-marker.mapboxgl-marker.mapboxgl-marker-anchor-center:hover {
   /* background: white; */
   cursor: pointer;
   padding: 2px;
   border-radius: 2px;
   display:flex;
   align-items: center;
   justify-content:center;
}

.live-vessel-box {
   position: relative;
   z-index: 1;
}

.live-vessel-box:hover .live-info-box {
   display: block;
   white-space: nowrap;
   width: auto;
   background-color: #6a6565eb;
   font-weight: 600;
   color: white;
   padding: 2px 6px;
   border-radius: 4px;
   left: 20px;
   bottom: 95%;
   z-index: 9999;
}

.live-info-box {
   position:absolute;
   display: none;
}

.live-info-box:before {
   content: '';
   display: block;
   height: 5px;
   width: 16px;
   position: absolute;
   border-radius: 6px;
   background: #6a6565cf;
   left: -8px;
   bottom: -3px;
   transform: rotate(-45deg);
}

.more-Info-Modal span,
.live-info-box span{  
   font-weight: 600;
   display: block;
}

.data-row.more-Info-Modal table {
   width: 100%;
}

.data-row.more-Info-Modal table td {
   padding: 4px 8px;
   border: 1px solid #9d8d8db5;
}

.Company-Header-details p.title, .invoice-inner-download p.title {
    font-size: 22px;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 1.5;
}

.Company-Header-details p.address {
    font-size: 12px;
}

table.table.table-bordered.table-invoice-report-colum {
   margin-top: 16px;
}

/* span.ant-picker-clear span.anticon.anticon-close-circle {
   display: none;
} */

.map-change-btn button.ant-btn.ant-btn-primary {
   border-radius: 5px;
   border: 1px solid #003e78 !important;
}

button.ant-btn.ant-btn-primary.btn-Active {
   background: white !important;
   color: #003e78 !important;
   border-color: #003e78;
}

.data-row.more-Info-Modal table tr:first-child {
   background: #68cd5c;
   color: black;
}

.data-row.more-Info-Modal table tr:nth-child(2) {
  background-color: palevioletred;
  color: black;
}


.data-row.more-Info-Modal tr td span{
   display: inline-block;
}
.data-row.more-Info-Modal tr,
.data-row.more-Info-Modal tr td,
.data-row.more-Info-Modal tr td span{
    transition: .35s ease;
}
.data-row.more-Info-Modal tr:hover,.data-row.more-Info-Modal tr:hover td {
    background: skyblue !important;
}
.data-row.more-Info-Modal tr:hover td span{
    padding-left: 10px;
}

table.noon_modal_table tr:nth-child(3),
table.noon_modal_table tr:nth-child(4){
    background: #8291ee;
    color: black
}

h2.reportHeading {
   font-size: 26px;
   margin: 0;
}

.Company-Header-details p.title, .invoice-inner-download p.title {
   font-size: 22px;
   margin-bottom: 0;
   font-weight: 600;
   line-height: 1.5;
   text-align: center;
}

/* .Company-Header-details p.address {
   font-size: 12px;
} */

