.layout_container {
  position: relative;
  margin-top: 10px;
}

.mail_list_item {
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 0;
  /* box-shadow: 0px 0px 15px #bfbfbf; */
  padding: 6px 8px;
  cursor: pointer;
  transition: 0.35s ease-in-out;
  position: relative;
  overflow: hidden;
  background: hsl(204deg 91.42% 90.82% / 30%);
}

.mail_list_item:hover {
  background-color: #ffffff;
}

.mail_list_container {
  padding: 5px;
  overflow: hidden;
}

/* 
.mail_list_item:not(:last-child) {
  margin-bottom: 8px;
} */

.mail_list_item p {
  margin: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mail_list_label_box {
  display: flex;
  padding-block: 3px;
}

.mail_list_badge {
  display: inline-block;
  background: #76b947;
  text-transform: capitalize;
  padding: 1px 8px;
  margin-right: 4px;
  border-radius: 20px;
  margin-top: 5px;
  color: #fff;
  /* border: 1px solid; */
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mail_list_item p:first-child {
  font-weight: 600;
  /* margin-bottom: 5px; */
  max-width: 265px;
  display: flex;
}

.mail_list_item p.subject,
.mail_list_item p.snippet {
  font-size: 13px;
}

.mail_list_item.current_active {
  background: #B2CBE4 !important;
  color: #000000;
  position: sticky;
  top: 0px;
  overflow: hidden;
  z-index: 1;
}

.mail_filter_row {
  display: flex;
  align-items: center;
  column-gap: 18px;

  /* margin-bottom: 10px; */
}

.filter_icons {
  color: white;
  line-height: 0;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #39b1ff;
  border-radius: 4px;
  background: #39b1ff;
}

.filter_icons svg {
  height: 22px;
  width: 22px;
}

.ant-drawer-content-wrapper:has(.mail_filter_drawer) {
  min-width: auto !important;
  margin-right: 0px !important;
}

.mail_upper_header {
  display: flex;
  justify-content: flex-end;
  /* position: absolute;
  top: 70px;
  right: 40px; */
  margin-top: -15px;
  margin-right: 12px;
}

.upper_header_btn {
  background: #39b1ff;
  color: white;
  line-height: 0;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 40px;
}

main.ant-layout-content {
  position: relative;
}

.no_current_mail {
  max-width: 240px;
  width: 100%;
  text-align: center;
  background: #12406a;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  transition: 0.5s ease-in-out;
}

.no_current_mail h4 {
  margin: 0;
  margin-block: 10px;
}

.mail_list_header {
  position: absolute;
  top: -23px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  margin-top: -30px;
  right: 60px;
}

.mail_list_header label.ant-checkbox-wrapper {
  margin-bottom: 0px !important;
}

.list-view span.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #12406a85 !important;
}

span.mail_list_time {
  font-size: 11px;
  font-weight: 600;
  color: #12406a;
}

.mail_list_label_box .mail_list_labels.vessels {
  display: flex;
  align-items: center;
  overflow-wrap: break-word;
  overflow-x: auto;
}

.mail_list_labels.vessels span.mail_list_badge {
  white-space: nowrap;
}

.dashboard-wrapper {
  padding: 14px;
  overflow-x: hidden;
}

.dashboard-wrapper canvas {
  height: 100% !important;
}

.dashboard-wrapper .slick-prev:before,
.dashboard-wrapper .slick-next:before {
  color: #000;
}

.mail_list_item.current_active span.mail_list_time {
  color: #000000;
}

.ant-drawer-content.mail_filter_drawer {
  box-shadow: 0px 0px 10px #bfbfbf;
}

/* ///////////////////// Current Mail CSS /////////////// */

.current_mail_header {
  display: flex;
  align-items: center;
  background: #e9e9e7;
  padding: 8px 16px;
  /* margin-block: 10px; */
  border-radius: 4px;
  box-shadow: 0px 0px 10px #bfbfbf54;
  column-gap: 8px;
  margin-bottom: 8px;
}

.user_mail_profile {
  background: #dc7c8d;
  min-width: 40px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
}

.current_mail_collapse .ant-collapse-item.ant-collapse-item-active {
  background: white;
}

.current_mail_collapse .ant-collapse-expand-icon {
  display: none !important;
}

.current_mail_collapse .ant-collapse-header {
  padding: 0 !important;
}

.mail_cc_bcc div {
  max-width: 200px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.mail_list_badge:empty {
  display: none;
}

.mail_cc_bcc {
  display: flex;
  align-items: center;
}

.mail_cc_bcc button.ant-dropdown-trigger {
  background: transparent;
  border: 0;
}

.current_mail_collapse .ant-collapse-content-box .current_mail_body {
  height: 85vh;
  overflow-y: auto;
  padding-right: 60px;
}

.current_mail_icons {
  font-size: 1.375rem;
  color: #000;
  /* color: rgba(47, 43, 61, 0.54);
  filter: drop-shadow(2px 4px 6px navy); */
  line-height: 1;
}

.mail_feature_icons {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 5px;
}

.mail_feature_icons .mail_header_icons:not(:last-child) {
  margin-right: 20px;
}

.current_mail_body_box {
  position: relative;
}

.current_mail_extra_features_tab {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  right: 6px;
  background: rgba(18, 64, 106, 0.818);
  padding: 8px 6px;
  border-radius: 4px;
}

.current_mail_extra_features_tab .mail_header_icons {
  text-align: center;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  /* filter: drop-shadow(2px 4px 6px #bfbfbf); */
}

.current_mail_subject_header {
  display: flex;
  justify-content: space-between;
}

.current_mail_subject_header h4 {
  margin-bottom: 10px;
}

.current_mail_header_labels {
  padding: 7px 0px;
}

.current_mail_header_labels span.mail_list_badge {
  margin-top: 0;
  margin-bottom: 4px;
}

.mail_filter_row_box {
  position: sticky;
  top: 0px;
  background: rgb(245 245 245);
  border-radius: 4px;
  padding: 6px 4px;
  /* box-shadow: 0px 0px 8px #bfbfbf; */
}

/* /////////////// LIST VIEW /////////////////////// */

.mail_list_snippet {
  white-space: nowrap;
  max-width: 23%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  color: #000;
}

.list-view .ant-list-item-meta {
  align-items: center !important;
}

.list-view .mail_filter_row_box {
  /*max-width: 350px;*/
  width: 80%;
  /* position: absolute;
  right: 90px;
  top: -50px; */
  background: transparent;
  box-shadow: none;
}

.list-view .mail_filter_row_box .mail_filter_row {
  margin: 0;
  padding-block: 5px;
}

.list-view .mail_filter_row_box .filter_icons {
  color: #ffffff;
  border-color: #39b1ff;
  background: #39b1ff;
}

.list-view .mail_list_sub {
  color: #000000;
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 180px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-view h4.ant-list-item-meta-title a {
  color: black;
}

.list-view .mail_list_description {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.list-view li.ant-list-item {
  padding-inline: 16px;
  transition: 0.35s ease;
  cursor: pointer;
  background-color: #fbf9f1 !important;
  padding: 6px 16px;
}

.list-view li.ant-list-item:hover {
  background: #fcfbfb !important;
}

.mail_list_labels.vessels span.mail_list_badge {
  background-color: rgb(230, 230, 250);
  color: rgb(75, 0, 130);
  border-color: rgb(75, 0, 130);
}

.no_current_mail:hover {
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 20px 20px 0px -3px #658cb0;
}

.mail_list_attach_icon {
  position: absolute;
  top: -1px;
  right: -32px;
  display: flex;
  flex-direction: column;
  height: calc(100% + 2px);
  justify-content: space-around;
  backdrop-filter: blur(10px);
  padding: 4px 6px;
  transition: 0.5s ease-out;
  /* background: hsl(208.64deg 70.97% 24.31% / 34%); */
  background: #fff;
  border-radius: 4px;
}

.list-view .ant-list-item-meta {
  position: relative;
}

.list-view .ant-list-item-meta:hover .mail_list_attach_icon {
  opacity: 1;
}

.mail_list_item:hover .mail_list_attach_icon {
  right: 0px;
}

.mail_list_view_container .mail_list_attach_icon {
  flex-direction: row;
  right: 0;
  width: 200px;
  align-items: center;
  top: -2px;
  opacity: -999999;
}

.mail_list_view_container .mail_list_attach_icon svg {
  font-size: 20px;
}

.mail_list_item.current_active p.sender_name * {
  color: #000000;
}

/* /////////////////// Filters /////////////////////////// */

.search-filters .filter-item {
  margin-bottom: 14px;
}

.search-filters .filter-item label {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 3px;
}

.mail_filter_row input.ant-input {
  border-radius: 4px !important;
}

/* //////////////////////// Scrollbar CSS /////////////////////////// */

/* width */
.mail_scrolls::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.mail_scrolls::-webkit-scrollbar-track {
  background: #d5d5d5;
}

/* Handle */
.mail_scrolls::-webkit-scrollbar-thumb {
  background: #12406a;
}

/* Handle on hover */
.mail_scrolls::-webkit-scrollbar-thumb:hover {
  background: #55a9c0;
}

/* Vertical white Scrolls */

/* width */
.mini_scrolls::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
.mini_scrolls::-webkit-scrollbar-track {
  background: #d5d5d5;
}

/* Handle */
.mini_scrolls::-webkit-scrollbar-thumb {
  background: #12406a93;
}

/* Handle on hover */
.mini_scrolls::-webkit-scrollbar-thumb:hover {
  background: #55a9c0;
}

/* =========== Tonnage Cargo Order =========== */

span.overflowTextWrap {
  display: flex;
  align-items: center;
  padding: 8px;
}

span.overflowText {
  padding-inline: 8px;
}

.tonnage-filter-table {
  width: 1800px;
  margin-bottom: 10px;
}

.tonnage-filter-table td {
  /* border: 1px solid lightgrey; */
  border: 1px solid #003e78;
  padding: 10px;
}

.tonnage-filter-table th {
  border: 1px solid #003e78;
  padding: 6px;
  text-align: center;
}

.table-responsive::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

td.filter-results span.ant-tag {
  font-size: 13px !important;
  padding: 2px 5px;
  text-transform: capitalize;
  color: #12406a;
  font-weight: 600;
}

.tonnage-filter-table.cargo {
  width: 2180px;
}

/* Track */
.table-responsive::-webkit-scrollbar-track {
  background: #003e7855;
}

/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background: #003e78;
}

/* Handle on hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

form.ant-form.ant-form-vertical.view_tonnage_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

form.ant-form.ant-form-vertical.view_tonnage_form .ant-form-item {
  width: 49%;
}

/* .timeline-item-details {
  display: flex;
  flex-wrap: wrap;
} */

.timeline-item-details p {
  display: flex;
  margin-bottom: 0;
  width: 50%;
}

.timeline-item-card .ant-card-body {
  padding: 10px 24px;
}

.mail_scrolls.myOrderBox {
  padding-right: 30px;
}

.mail_scrolls.myOrderBox .ant-card-body {
  text-align: left;
}

table.bestMatchUpperTable {
  width: 100%;
}

table.bestMatchUpperTable td {
  padding: 3px;
}

.tonnage-table-upper-box button {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding: 0;
}

.overflowText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-view-filter-table table {
  width: 100%;
}

.list-view-filter-table table td {
  padding: 10px 10px;
}

/* .list-view-filter-table table td {
  border: 1px solid lightgrey;
} */

tr.chips-table-row td {
  text-align: center;
  padding: 4px;
  vertical-align: middle;
}

.list-view-filter-table {
  /*padding-inline: 8px;*/
  margin-top: 5px;
}

.floating-whatsapp.react-draggable {
  display: none;
}

.ReadBy-text {
  color: #000 !important;
}

span.list_view_tags {
  padding: 2px 10px 2px 10px;
  background: #167d7f;
  color: #fff;
  /* border: 1px solid; */
  border-radius: 20px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 2px;
  display: inline-block !important;
  min-width: 60px;
}

span.ReadBy-text {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-inline: 5px;
}

.tag_snipet {
  white-space: nowrap;
  max-width: 23%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list_view_tags_box {
  width: 15%;
  text-align: center;
}

.list_view_tags_box span {
  /* display: flex; */
  align-items: center !important;
}

.mail_list_labels.mailType {
  width: 8%;
  text-align: center;
}

.list-view-timestamp {
  width: 100px;
  text-align: center;
  padding-inline: 8px;
  display: inline-table;
}

.mail_list_labels.mail_vessels {
  width: 130px;
  overflow: hidden;
  text-align: center;
}

table.list-view-title-row {
  width: 100%;
}

.list-view-title-row-box {
  /* padding-inline: 20px; */
}

table.list-view-title-row td {
  text-align: center;
  font-weight: 600;
  padding-block: 8px;
}

.list-view .mail_list_description .ant-avatar-group {
  flex-grow: 1;
  justify-content: center;
}

.mail_list_labels.mail_vessels span.mail_list_badge {
  background: #055c9d;
  color: #fff;
  width: 100%;
}

.mail_list_badge.cargo {
  background: rgb(57, 161, 6) !important;
  color: #ffffff !important;
}

.mail_list_badge.tonnage {
  background: rgb(255, 169, 5) !important;
  color: #ffffff !important;
}

.mail_list_badge.other {
  background-color: rgb(227 227 227) !important;
  color: #000000 !important;
}

.mail_list_view_container .mail_list_labels.mailType .mail_list_badge {
  width: 100%;
}

.selected_items_box {
  display: flex;
  margin: 0 auto;
  gap: 25px;
  padding: 2px 8px;
  max-width: 300px;
  width: 100%;
  justify-content: normal;
  font-size: 20px;
  cursor: pointer;
}

.list-view li.ant-list-item.not-read {
  background: #fff;
}

ul.ant-timeline.TimelineBestMatch .ant-card-head {
  /* background: #3fa4ad; */
  min-height: auto;
  padding: 6px 16px;
}

ul.ant-timeline.TimelineBestMatch .ant-card.timeline-item-card {
  border: 1px solid;
  margin: 0px !important;
}

span.vessel-name-ellipsis {
  max-width: 140px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.ant-timeline-item-tail {
  border-color: #003e78 !important;
}

.mail-pagination {
  display: flex;
  white-space: nowrap;
  column-gap: 20px;
  font-weight: 700;
  align-items: center;
}

.highlight {
  background-color: yellow !important;
}

p.sender_name {
  display: flex;
  align-items: center;
}

.new_filter_name_box .ant-modal-body {
  padding: 0px;
  margin-top: 16px;
}

/* styles.css */
.row-hover-1:hover {
  background-color: #f5f5f5;
  /* Light grey */
}

.row-hover-2:hover {
  background-color: #e0f7fa;
  /* Light cyan */
}

.row-hover-3:hover {
  background-color: #ffe0b2;
  /* Light orange */
}

.resizing-grip {
  width: 6px;
  cursor: col-resize;
  background-color: #ccc;
  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  user-select: none;
}

.analysis-button {
  padding: 5px 10px;
  border: 1px solid rgba(47, 43, 61, 0.2);
  background-color: rgb(224, 247, 250);
  border-radius: 20px;
  cursor: pointer;
}

.bstyle {
  padding: 10px;
  color: rgba(47, 43, 61, 0.78);
  font-weight: 600;
}

.Tagcss {
  border: 1px solid #1b6f14;
  color: #1b6f14;
  background-color: #f2fde2;
  border-radius: 20px;
}

/*  Tooltip showing for select text suggestion  */
.mailTooltip {
  padding: 4px 6px !important;
  border: 1px solid #c0d0df !important;
  border-radius: 6px !important;
  background-color: #e2ecf3 !important;
}

.mailTooltipInfo {
  font-size: 0.8rem;
  padding: 10px 3px;
}

.button-suggestion {
  margin: 0px 2px;
  color: #2f3349;
}

.mailTooltip button-suggestion svg {
  font-weight: bold;
  color: #2f3349;
  font-size: 18px;
}