/* For all browsers */
.custom-scroll {
  cursor: pointer;
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgb(204, 206, 205); /* For Firefox */
}

/* For WebKit browsers (Chrome, Safari) */
.custom-scroll::-webkit-scrollbar {
  background: transparent;
  width: 4px; /* Width of the scrollbar */
}
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 20px; /* Roundness of the scrollbar */
}


/* Example of increased specificity */
.ant-input.input {
  margin-bottom: 1rem;
  border-radius: 6px !important;
  outline: 2px solid rgb(186, 229, 215) !important;
  box-shadow: none !important; 
}

.colorcircle {
  cursor: pointer;
  border: 2.5px solid rgb(102, 205, 170);
  display: flex;
  height: 42px;
  justify-content: center;
  width: 42px;
  border-radius: 50%;
  margin-bottom: 0.3rem;
}

.colorcircle2 {
  cursor: pointer;
  border: 2.5px solid rgb(102, 205, 170);
  display: flex;
  height: 42px;
  justify-content: center;
  width: 42px;
  border-radius: 50%;
  margin-bottom: 0rem;
}

.emojistyle {
  cursor: pointer;
  font-size: 22px;
  color: rgba(35, 34, 41, 0.78);                              
  margin-top: 0.5rem;
}

.messageinput {
  border-radius: 8px;
  height: 4rem;
  font-size: 17px;
  width: 100%;
  border: 3px solid rgb(102, 205, 170);
}

.send-message-icon {
  cursor: pointer;
  font-weight: 400;
  font-size: 22px;
  margin-top: 0.5rem;
  margin-left: 0.2rem;
}

.editchatbt {
  cursor: pointer;
  font-size: 1rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  background-color: transparent;
  border: none;
  color: black;
}

.group-input {
  margin-bottom: 0.6rem;
  width: 85%;
  height: 2.5rem;
  padding: 1rem;
  border-radius: 7px;        
  border: 2.5px solid rgb(102, 205, 170);
}

.create-group-btn {
  font-size: 26px;
  color: white;
  cursor: pointer;
}


