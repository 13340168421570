.port-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  // background-color: #f4f5f7;
  background: #fff;
  border: 1px solid #00ffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.port-card {
  // background: #fff;
  // border: 1px solid #ddd;
  // border-radius: 8px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  .port-name {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #2c3e50;
  }

  .section {
    margin-bottom: 20px;
    margin-bottom: 20px;
    // border: 2px solid #015659;
    padding: 1rem;
    border-radius: 1rem;
    // background: rgba(0, 255, 255, 0.5);
    // background: linear-gradient(to left, #11998e 0%, #38ef7d 100%);
    // background: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
    // background: linear-gradient(to right, #2b5876 0%, #4e4376 100%);
    background: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
    box-shadow: 0 0 10px rgba(4, 99, 99, 0.8);

    h3 {
      font-size: 18px;
      color: red;
      margin-bottom: 10px;
      border-bottom: 2px solid #3498db;
      display: inline-block;
    }

    p {
      margin: 5px 0;
      font-size: 16px;
      color: #015659;

      strong {
        color: #27ae60;
      }
    }
  }
}
.total-cost {
  text-align: center;
  background: #ecf9ec;
  padding: 1rem;
  border-radius: 8px;
  font-size: 18px;
  background: linear-gradient(to right, #f7797d 0%, #fbd786 50%, #c6ffdd 100%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  p {
    font-weight: bold;
    font-size: 22px;
  }
}

.json-display {
  background-color: #f4f4f4;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.json-display pre {
  background-color: #2d2d2d;
  color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  font-family: "Courier New", Courier, monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.ai-active {
  // background: #f2c4c4;
  background: linear-gradient(to bottom, #f7f6ed, #78ced0);
  color: #015659;
  &hover {
    background: #00ffff;
  }
}
