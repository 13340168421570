$profit-color: #208c30;
$loss-color: #db1b1b;
$fixed-text-color: #eee;
$border-color: #e8e8e8;


// .port-to-port-page .ant-table-thead > tr > th,
// .port-to-port-page .ant-table-footer, .ant-table-thead > tr > th {
//   text-align: left !important;
// }
// .port-to-port-page .ant-table-tbody > tr > td {
//   padding: 5px 10px !important;
// }
// .port-to-port-page .ant-table-tbody > tr:last-of-type {
//   background-color: #488A99 !important;
//   color: #ffffff !important;
// }

// .port-to-port-page .ant-table-wrapper {
//   overflow-x: auto;
// }

// .port-to-port-calc {
//   margin-top: 20px;

//   .tbl-form-block {
//     display: flex;
//     width: 90%;
//     font-size: 13px;
//     border-radius: 5px;
//     margin-bottom: 20px;
//     border: 1px solid $border-color;
//     overflow: hidden;
//     max-width: 90%;

//     input {
//       max-width: 100px;
//       font-size: 13px;
//     }

//     .tbl-form-item {
//       position: relative;
//       width: 50%;
//     }

//     .tbl-form-title {
//       font-weight: bold;
//       background-color: #488A99;
//       color: #fff;
//       padding: 5px;
//       padding-bottom: 8px;
//       min-width: 100px;
//       border-bottom: 1px solid $border-color;
//     }

//     .tbl-form-content {
//       padding: 6px 8px;
//       min-height: 35px;
//       border-bottom: 1px solid $border-color;
//       text-overflow: ellipsis;
//       overflow: hidden;
//       white-space: nowrap;

//       &:last-of-type {
//         border: none;
//       }

//       &.tbl-profit {
//         background-color: $profit-color;
//         color: #fff;
//       }

//       &.tbl-loss {
//         background-color: $loss-color;
//         color: #fff;
//       }

//       &.fixed-text {
//         background-color: $fixed-text-color;
//       }
//     }

//     .tbl-form-item + .tbl-form-item {

//       .tbl-form-title,
//       .tbl-form-content {
//         padding-left: 10px;
//         border-left: 1px solid $border-color;
//       }
//     }
//   }}

//   .ant-btn .ant-btn-primary{
// float: left;

//   } 

//   .map-wrapper-container .article .box-default .box-body{
//     padding: .5rem;
//   }

//   .map-wrapper-container .article .ant-form-item-label label{
//     width: 84px
//   }



//   .row-padding{
//     padding-bottom:3px
//   }
//   .map-padding{
//     padding-left: 3px;
//   }

.map-wrapper-container{
  max-width: 1440px;
  margin: 0 auto;
  .map-top-container{
      margin-bottom: 25px;
      h2{
          font-size: 34px;
          line-height: 1;
          text-align: center;
          margin-bottom: 10px;
      }
      span{
          line-height: 1.5;
          font-size: 20px;
      }
  }
  .map-wrapper{
      .map-container{
          height: 500px;
      }
      
  }
}


  // css copied from website port to port map.css

  
  
  .sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
  }
  
  .port-to-port-page .ant-table-thead > tr > th,
  .port-to-port-page .ant-table-footer,
  .ant-table-thead > tr > th {
    text-align: left !important;
  }
  
  .port-to-port-page .ant-table-tbody > tr > td {
    padding: 5px 10px !important;
  }
  
  // .port-to-port-page .ant-table-tbody > tr:last-of-type {
  //   background-color: #488a99 !important;
  //   color: #ffffff !important;
  // }
  
  .port-to-port-page .ant-table-wrapper {
    overflow-x: auto;
  }
  
  .port-to-port-calc {
    margin-top: 20px;
  }
  .port-to-port-calc .tbl-form-block {
    display: flex;
    width: 90%;
    font-size: 13px;
    border-radius: 5px;
    margin-bottom: 20px;
    border: 1px solid #e8e8e8;
    overflow: hidden;
    max-width: 90%;
  }
  .port-to-port-calc .tbl-form-block input {
    max-width: 100px;
    font-size: 13px;
  }
  .port-to-port-calc .tbl-form-block .tbl-form-item {
    position: relative;
    width: 50%;
  }
  .port-to-port-calc .tbl-form-block .tbl-form-title {
    font-weight: bold;
    background-color: #488a99;
    color: #fff;
    padding: 5px;
    padding-bottom: 8px;
    min-width: 100px;
    border-bottom: 1px solid #e8e8e8;
  }
  .port-to-port-calc .tbl-form-block .tbl-form-content {
    padding: 6px 8px;
    min-height: 35px;
    border-bottom: 1px solid #e8e8e8;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .port-to-port-calc .tbl-form-block .tbl-form-content:last-of-type {
    border: none;
  }
  .port-to-port-calc .tbl-form-block .tbl-form-content.tbl-profit {
    background-color: #208c30;
    color: #fff;
  }
  .port-to-port-calc .tbl-form-block .tbl-form-content.tbl-loss {
    background-color: #db1b1b;
    color: #fff;
  }
  .port-to-port-calc .tbl-form-block .tbl-form-content.fixed-text {
    background-color: #eee;
  }
  .port-to-port-calc .tbl-form-block .tbl-form-item + .tbl-form-item .tbl-form-title,
  .port-to-port-calc .tbl-form-block .tbl-form-item + .tbl-form-item .tbl-form-content {
    padding-left: 10px;
    border-left: 1px solid #e8e8e8;
  }
  
  .ant-btn .ant-btn-primary {
    float: left;
  }
  
  .map-wrapper-container .article .box-default .box-body {
    padding: 0.5rem;
  }
  
  
  .map-wrapper-container .article .ant-form-item-label label {
    width: 92px;
  }


  .row-padding {
    padding-bottom: 3px;
  }
  
  .map-padding {
    padding-left: 3px;
    position: relative;
  }
  
  .map-container {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100%;
   height: 100%;
  } /*# sourceMappingURL=map.css.map */
  
  .port-label {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
  
  .port-label1 {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }
  
  .page_back {
    background-color: #488a99;
    overflow: hidden;
  }
  
  .marker-hover {
    cursor: pointer;
  }
  
  .btn-draw-polygon {
    position: absolute;
    top: 30%;
    right: 8px;
    padding: 8px;
    border-radius: 100%;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
  }
  
  .btn-draw-polygon,
  .btn-draw-polygon:hover,
  .btn-draw-polygon:active,
  .btn-draw-polygon:focus {
    outline: none;
    box-shadow: none;
  }
  
  .btn-draw-polygon:hover {
    background-color: rgba(73, 73, 73, 0.3);
  }
  
  .draw-container {
    position: absolute;
    left: 50%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100px;
    z-index: 20;
  }
  
  .draw-mode-text {
    background-color: rgb(0, 0, 0);
    color: rgba(255, 255, 255,1);
    font-size: 14px;
    padding: 4px 8px;
    text-align: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .draw-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
  }
  
  .btns-draw {
    padding: 8px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
  }
  
  .btns-draw:hover {
    background-color: rgba(73, 73, 73, 0.3);
  }
  
  .btns-draw,
  .btns-draw:hover,
  .btns-draw:active,
  .btns-draw:focus {
    outline: none;
    box-shadow: none;
  }
  
  .btn-clear {
    position: absolute;
    right: 8px;
    top: 37%;
    padding: 8px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(32, 140, 48, 0.8);
    color: white;
    font-size: 14px;
    z-index: 20;
  }
  
  .btn-clear,
  .btn-clear:hover,
  .btn-clear:active,
  .btn-clear:focus {
    background-color: rgba(32, 140, 48, 1);
    outline: none;
    box-shadow: none;
  }
  

  .mapboxgl-map {
 
    font: 12px/20px Helvetica Neue,Arial,Helvetica,sans-serif;
    overflow: hidden !important;;
    position: relative;
  }