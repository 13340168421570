.map-container {
    height: 600px;
}

.vessel-search-list .search-input {
    margin-bottom: 15px;
}

.vessel-search-list .list-data {
    position: relative;
    max-height: 450px;
    overflow-y: auto;
}

.vessel-search-list .list-data .list-item {
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease-in-out;
    padding: 5px 10px;
}

.vessel-search-list .list-data .list-item:hover {
    background-color: #f7f7f7;
}

.vessel-search-list .list-data .list-item .vessel-name {
    font-weight: bold;
}

.vessel-search-list .list-data .list-item .middle-wrapper-discription {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.ci-table-block {
    position: relative;
}

.ci-table-block .ant-table {
    margin-top: 10px;
}

.ci-table-block .ant-table-body .ant-table-thead>tr th {
    background-color: #488a99 !important;
}

.ci-table-block .ant-table-body .ant-table-tbody>tr td {
    padding: 2px !important;
}

.rating-col {
    text-align: center;
}

.rating-color-a {
    background-color: #208c30;
}

.rating-color-b {
    background-color: #72bd22;
}

.rating-color-c {
    background-color: #f4e91a;
}

.rating-color-d {
    background-color: #f4cc1a;
}

.rating-color-e {
    background-color: #d51515;
}