.app-sidenav {
  .bg-dark,
  .bg-primary,
  .bg-success,
  .bg-info,
  .bg-danger {
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }
  }

  .bg-warning,
  .bg-white {
    a {
      color: $body-color;
      &:hover {
        color: $body-color;
      }
    }
  }
}

.sidenav-bg-light {
  background-color: $white;

  .sidenav-footer {
    color: $body-color;
    a {
      color: $body-color;
      &:hover {
        color: $body-color;
      }
    }
  }
}
.sidenav-bg-dark {
  background-color: $dark;

  .sidenav-footer {
    color: $white;
    opacity: .85;
    a {
      color: $white;
      opacity: .85;
      &:hover {
        color: $white;
        opacity: 1;
      }
    }
  }
}