.featureService .masonaryWrap{border-radius: 10px; overflow: hidden; cursor: pointer;}
.featureService .masonaryWrap img {width: 100%; height: auto; transition: transform 0.3s ease;}
.featureService .masonaryWrap:hover img {transform: scale(1.1);}
.featureService .masonaryWrap .image-container {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.featureService .masonaryWrap .image-container img {
  display: block;
  width: 100%;
  height: auto;
}
.featureService .masonaryWrap .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  margin: 0px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  /* padding: 8px; */
  text-align: center;
  opacity: 1; /* Title is visible by default */
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column
}
.featureService .masonaryWrap:nth-child(even) .title{background-color: rgba(46, 146, 253, 0.9);}
.featureService .masonaryWrap:nth-child(4n) .title{background-color: rgba(240, 197, 13, 0.9);}

.featureService .masonaryWrap .description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.8rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 8px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.featureService .masonaryWrap .image-container:hover .description {
  opacity: 1;
}
/* styles/ using with react example */
.featureService .masonry-grid {
  column-count: 5;
  column-gap: 16px;
}

.featureService .masonry-item {
  break-inside: avoid;
  margin-bottom: 16px;
}

/* another card css */
.alertDialogWrap .ant-modal-body{padding: 0px;}
.alertDialogWrap .ant-modal-body .ant-card-body{padding-top: 3px; padding-bottom:3px;}
.featureService2 .masonaryWrap{border-radius: 10px; overflow: hidden; cursor: pointer;}
.featureService2 .featureInfo{display: block; text-align: center; font-size: 1rem;}
.featureService2 .featureInfoSub{background-color: #2e92fde6; color: #fff; text-align: center;
  margin-top: 0px; margin-bottom: 15px;  padding: 5px; font-size: 1.2rem;}
.featureService2 .headingTxt {font-size: 0.8rem;}
.featureService2 .listText {font-size: 0.7rem; margin-left: 0px; padding: 6px 0px;}
.featureService2 .masonaryWrap img {width: 100%; height: auto; transition: transform 0.3s ease;}
.featureService2 .masonaryWrap:hover img {transform: scale(1.1);}
.featureService2 .masonaryWrap .image-container {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.featureService2 .masonaryWrap .image-container img {
  display: block;
  width: 100%;
  height: auto;
}
.featureService2 .masonaryWrap .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  margin: 0px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  /* padding: 8px; */
  text-align: center;
  opacity: 1; /* Title is visible by default */
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column
}
/* .featureService2 .masonaryWrap:nth-child(even) .title{background-color: rgba(46, 146, 253, 0.9);}
.featureService2 .masonaryWrap:nth-child(4n) .title{background-color: rgba(240, 197, 13, 0.9);} */

.featureService2 .masonaryWrap .description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.8rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 8px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.featureService2 .masonaryWrap .image-container:hover .description {
  opacity: 1;
}
/* styles/ using with react example */
.featureService2 .masonry-grid {
  /* column-count: 5; */
  column-gap: 16px;
  display: flex;
  flex-wrap: wrap;
}

.featureService2 .masonry-item {
  break-inside: avoid;
  margin-bottom: 16px;
  margin-bottom: 16px;
  width: 32%;
}

@media (max-width: 1024px) {  
  .featureService .masonry-grid {
    column-count: 4;
  }
}

@media (max-width: 640px) {
  .featureService .masonry-grid {
    column-count: 2;
  }  
  .featureService2 .masonry-item{
    width:46%;
  }
  .featureService2 .gridColumn{
    margin-bottom: 10px;
  }
  .alertDialogWrap .ant-modal-body .ant-card-body{ margin-bottom: 15px;}
}
@media (max-width: 480px) { 
  .featureService .masonry-grid {
    column-count: 1;
  }   
  .featureService2 .masonry-item{
    width:100%;
  }
}