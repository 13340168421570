.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.loading-text {
  font-size: 27px;
  margin-bottom: 10px;
}

.loading-subtext {
  font-size: 20px;
}

/* Menu Bar */
.menu-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 10px;
  //   background: linear-gradient(90deg, #68b109, #ff1e56, #ff007f);
  //   background: linear-gradient(to right, #00c6ff 0%, #0072ff 100%);
  //   background: linear-gradient(to right, #ff7e5f 0%, #feb47b 100%);
  //   background: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
  //   background: linear-gradient(to right, #11998e 0%, #38ef7d 100%);00000
  //   background: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
  //   background: linear-gradient(to right, #2b5876 0%, #4e4376 100%);
  //   background: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);1111
  //   background: linear-gradient(to right, #7f7fd5 0%, #86a8e7 50%, #91eae4 100%);
  //   background: linear-gradient(to right, #f953c6 0%, #b91d73 100%);
  background: linear-gradient(to right, #1e0e95 0%, #1d57ea 50%, #43cde5 100%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin: 20px auto;
  max-width: 1200px;
}

/* Menu Item */
.menu-item {
  background: linear-gradient(to bottom, #3ac2ce, #29b8c0);
  //   background: linear-gradient(to right, #f7797d 0%, #fbd786 50%, #c6ffdd 100%);
  //   background: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
  //   background: linear-gradient(to right, #11998e 0%, #38ef7d 100%);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  //   text-transform: uppercase;
  text-align: center;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
}
.menu-bar2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  background: linear-gradient(to right, #1e0e95 0%, #1d57ea 50%, #43cde5 100%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin: 20px auto;
  max-width: 1200px;
}

.menu-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.3), transparent);
  transition: all 0.5s ease;
  z-index: 1;
}

.menu-item:hover::before {
  left: 100%;
}

.menu-item:hover {
  transform: translateY(-5px) scale(1.05);
  // box-shadow: 0 10px 20px rgba(255, 30, 86, 0.6);
  // background: linear-gradient(to bottom, #ff4e87, #ff1e56);
}

/* Neon Glow Effect */
// .menu-item:hover {
//   text-shadow: 0 0 8px #ff1e56, 0 0 15px #ff4e87, 0 0 20px #ff4e87,
//     0 0 30px #ff1e56;
// }

/* Ripple Effect */
.menu-item:active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: ripple 0.6s ease-out;
  z-index: 0;
}

@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

/* Responsive Styling */
@media (max-width: 768px) {
  .menu-bar {
    flex-direction: column;
    padding: 10px;
  }

  .menu-item {
    width: 80%;
    margin: 10px auto;
  }
}

.mapbox-gl-container {
  position: relative;
  width: 100%;
  height: 50vh;
  margin-top: 3rem;
  // background: #141e30;

  .map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  /* Info Box Styling */
  .info-box {
    position: absolute;
    top: 20px;
    left: 20px;
    // background: rgba(0, 0, 0, 0.8);
    // background: rgba(0, 255, 255, 0.5);
    // background: linear-gradient(to right, #11998e 0%, #38ef7d 100%);
    // background: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
    // background: linear-gradient(to right, #2b5876 0%, #4e4376 100%);
    // background: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
    border: 2px solid #00adff;
    border-radius: 12px;
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
    color: #015659;
    opacity: 0.8;
    font-weight: 600;
    padding: 15px;
    // border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1;

    h2 {
      margin: 0 0 10px;
      color: #950a23;
    }

    p {
      margin: 5px 0;
      font-size: 14px;
    }
  }
}

.map-container {
  position: relative;
  width: 100%;
  height: 80vh;
  margin-bottom: 20px;
}

.vessel-marker {
  width: 30px;
  height: 30px;
  background-color: #ff4500;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(255, 69, 0, 0.8), 0 0 30px rgba(255, 69, 0, 0.5);
  animation: glow 2s infinite;
}

.waypoint-marker {
  width: 15px;
  height: 15px;
  background-color: #00ffff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.8);
}

@keyframes glow {
  0%,
  100% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 69, 0, 0.8);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 25px rgba(255, 69, 0, 1);
  }
}

.map-details {
  text-align: center;
  color: #fff;
  background: linear-gradient(45deg, #141e30, #243b55);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.map-details h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #00ffff;
}
.no-data-message {
  text-align: center;
  color: #ff4500;
  font-size: 18px;
  padding: 20px;
  background: rgba(255, 69, 0, 0.1);
  border: 1px solid rgba(255, 69, 0, 0.5);
  border-radius: 10px;
}

.map-container {
  width: 100%;
  // height: 500px;
  border: 2px solid #00ffff;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
}

.vessel-marker {
  width: 20px;
  height: 20px;
  background: #ff4500;
  border-radius: 50%;
  border: 3px solid #ffffff;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 69, 0, 0.7);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(255, 69, 0, 1);
  }
}

.waypoint-marker {
  width: 15px;
  height: 15px;
  background: #00ffff;
  border: 2px solid #fff;
  border-radius: 50%;
}

.no-data-message {
  text-align: center;
  color: #ff4500;
  font-size: 18px;
  padding: 20px;
  background: rgba(255, 69, 0, 0.1);
  border: 1px solid rgba(255, 69, 0, 0.5);
  border-radius: 10px;
}

.map-details {
  margin-top: 20px;
  text-align: left;
  color: #00ffff;
  font-size: 16px;
}
.ai-active {
  background: #00ffff;
  color: #015659;
  &hover {
    background: #00ffff;
  }
}
