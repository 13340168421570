.locker {
  //   pointer-events: none;
  cursor: not-allowed;
  .full-width {
    span,
    svg {
      pointer-events: visible !important;
      cursor: pointer;
    }
  }
  div {
    cursor: not-allowed;
    pointer-events: none;
  }
  button {
    cursor: not-allowed;
    pointer-events: none;
  }
  button:not(:disabled) {
    cursor: not-allowed;
    pointer-events: none;
  }
  span {
    cursor: not-allowed;
    pointer-events: none;
  }
  label {
    cursor: not-allowed;
    pointer-events: none;
  }
  a {
    cursor: not-allowed;
    pointer-events: none;
  }
  .cancel {
    cursor: not-allowed;
    pointer-events: none;
  }
}

// lock aniamtion

.neon-lock-container {
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.neon-lock-container:hover {
  transform: scale(1.1);
}

.neon-lock {
  position: relative;
  width: 100px;
  height: 120px;
  background: linear-gradient(145deg, #282c34, #1b1f26);
  border-radius: 10px;
  box-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #00f0ff, 0 0 50px #00f0ff,
    0 0 75px #00c2ff, 0 0 100px #00aaff;
  transition: all 0.5s ease;
}

.neon-lock.locked::before {
  content: "";
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  border: 5px solid #00f0ff;
  border-radius: 50%;
  background: radial-gradient(circle, #14141f, #0d0d1f);
  box-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #00f0ff, 0 0 50px #00f0ff;
  transition: top 0.5s ease;
}

.neon-lock.unlocked::before {
  top: -25px; /* Move the arc down when unlocked */
}

.lock-status {
  margin-top: 15px;
  font-size: 20px;
  color: #00f0ff;
  text-shadow: 0 0 10px #00f0ff, 0 0 20px #00f0ff, 0 0 30px #00aaff;
  transition: color 0.3s ease;
}

.neon-lock-container.locked .lock-status {
  // color: #fff;
  color: rgb(161, 55, 55);
  // text-shadow: 0 0 10px #ff007a, 0 0 20px #ff007a, 0 0 30px #ff0055;
  text-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red;
}

.neon-lock-container.unlocked .lock-status {
  color: #015659; /* Neon green for unlocked */
  text-shadow: 0 0 10px #00ff7a, 0 0 20px #00ff7a, 0 0 30px #00cc5a;
}
