.map-container {
    height: 400px;
    overflow: hidden !important;
}

.vessel-pop:hover {
    background-color: aqua;
    transform: scale(1.04)
}
.vessel-pop-one:hover {
    background-color: rgb(185, 239, 239);
    transform: scale(1.04)
}
.vessel-pop-two:hover {
    background-color: rgb(145, 117, 145);
    transform: scale(1.04)
}
.vessel-pop-three:hover {
    background-color: rgb(176, 230, 153);
    transform: scale(1.04)
}
.vesselhistorymodal {
    margin-top: 0;
    margin-bottom: 1rem;
    width: 80% !important;
  }
.row1-white {
    background-color: #ffffff;
}

.row1-grey {
    background-color: #e8e6e6;
}

.red-gradient-button1 {  
    background: linear-gradient(to right, #D31027 0%, #EA384D  51%, #D31027  100%);
    color: white;
    font-weight: 500;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 14px;
    transition: background 0.6s ease;
}

.red-gradient-button1:hover {
    background: linear-gradient(135deg, #ff6666, #ff1a1a);
    color: white;
    font-weight: 500;
}