.app-footer.app-footer-custom {
  // make footer same height with sidenav footer
  padding: 11px $grid-gutter-width 12px;
  border-top: 1px solid rgba(0,0,0,.05);
  background-color:  #12406a;

  .small:not(:last-child) {
    position: relative;
    padding-right: $list-inline-padding;
    margin-right: $list-inline-padding;
    &:after {
      content: '';
      position: absolute;
      // background-color: rgba($dark, .2);
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      margin-top: -5px; // height/2
      height: 10px;
    }

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      padding-right: 0;
      margin-right: 0;
      &:after {
        content: normal;
      }
    }
  }
}

.market-btn {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0.6rem;
  -webkit-transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  border: 1px solid #e7e7e7;
  background-position: center left 0.75rem;
  background-color: #fff;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  text-decoration: none;

  &:hover {
    background-color: #000; /* Example hover effect on the button */
    border-color: #000;
    color: #fff;
    span{
      color: #fff;
    }
  }

  .market-button-title {
    display: block;
    color: #222;
    font-size: 1.125rem;
    
    &:hover {
      color: #fff;
    }
  }

  .market-button-subtitle {
    display: block;
    margin-bottom: -0.25rem;
    color: #888;
    font-size: 0.75rem;

    &:hover {
      color: #fff;
    }
  }
}

