$line_height: 30px;

.sash-container {
  position: relative;
}

.sash {
  display: block;
  position: absolute;
  left: 0;
  right: auto;
  top: 25px;
  z-index: 1;

  //
  .anticon {
    font-size: 14px;
    height: 20px;
    line-height: $line_height;
  }

  // 
  .material-icons {
    font-size: 14px;
    height: 20px;
    line-height: $line_height;
  }
}

.sash-icon-bold {
  .anticon {
    font-weight: bold;
  }
}

.sash {
  cursor: default;
  overflow: visible;
  pointer-events: none;
  position: absolute;
  background-color: $gray-600;
  color: $white;

  > div {
    position: relative;
    z-index: 1;
    height: $line_height;
    line-height: $line_height;
    transition: width 0.1s 0.05s cubic-bezier(0.86, 0, 0.07, 1);
    width: 25px;
    &:hover {
      -webkit-transition-delay: 0;
      transition-delay: 0;
      width: 100%;

      .sash-text {
        opacity: 1;
        width: 100%;
        padding-left: 15px;
      }
    }
  }

  .sash-text {
    display: block;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s, padding 0.25s, width 0.25s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    white-space: nowrap;
    width: 3em;

    float: left;
    margin-left: 5px;
    padding-right: 20px;
  }
  i {
    position: absolute;
    right: 1px;
    margin-left: 5px;
    &:before {
      color: #f5f5f5;
    }
  }

  &.sash-triangle-right:after {
    background-color: transparent;
    border: 15px solid $gray-600;
    border-right-color: transparent;
    bottom: 0;
    content: " ";
    position: absolute;
    right: -20px;
  }

  // Colors
  &.sash-primary { @include sash-variant ($primary) }
  &.sash-info { @include sash-variant ($info) }
  &.sash-success { @include sash-variant ($success) }
  &.sash-warning { @include sash-variant ($warning) }
  &.sash-danger { @include sash-variant ($danger) }
  &.sash-white {
    background-color: $white;
    color: $body-color;
    // anticon
    i:before { color: $body-color; }
    
    &.sash-triangle-right:after {
      border-color: $white;
      border-right-color: transparent;
    }
  }
}

