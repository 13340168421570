.read-unread-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  .read-section {
    color: rgb(96, 125, 59);
    cursor: default;
    font-weight: 500;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    .icon {
      margin: 5px;
    }
  }
  .unread-section {
    color: rgb(216, 142, 61);
    cursor: default;
    font-weight: 500;
    display: flex;
    align-items: center;
    .icon {
      margin: 5px;
    }
  }
}

.sender-tool-tip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: auto;
  // margin-right: 3rem;
  .sub-details {
    display: flex;
    flex-direction: column;
  }
}
