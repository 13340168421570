/* Tabs container styling */
.ai-tracker-tabs .ant-tabs {
  width: 100%;
}

/* Align navigation of tabs */
.ai-tracker-tabs .ant-tabs-nav {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Box shadow for tabs container */
  border-radius: 5px; /* Rounded corners for the tabs container */
  background: #adc2f3; /* Background for the tabs container */
  padding: 5px; /* Optional: Add padding for a neat appearance */
}

/* Style individual tabs */
.ai-tracker-tabs .ant-tabs-tab {
  flex: 1;
  text-align: center;
  color: black !important;
  background: #b4e6fb; /* Background for inactive tabs */
  border-radius: 5px; /* Rounded corners for individual tabs */
  transition: background 0.3s, color 0.3s; /* Smooth transitions */
  box-sizing: border-box; /* Ensure padding and border do not reduce width */
  padding: 10px; /* Space inside the tabs */
  margin: 0; /* Remove margin to maintain full width */
  font-weight: bold;
}

/* Add spacing between tabs */
.ai-tracker-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 5px; /* Add a small gap between tabs */
}

/* Hover effect for tabs */
.ai-tracker-tabs .ant-tabs-tab:hover {
  color: #00c6ff;
  /* background: #d0d0d0;  */
}

/* Active tab styling */
.ai-tracker-tabs .ant-tabs-tab-active {
  font-weight: bold;
  background: linear-gradient(to right, #00c6ff 0%, #0072ff 100%);
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 55vw;
}

/* Active tab text color */
.ai-tracker-tabs .ant-tabs-tab-btn {
  color: white !important;
}

/* Adjust text color for inactive tabs */
.ai-tracker-tabs .ant-tabs-tab-btn {
  color: inherit !important; /* Ensure text color matches the tab's color */
}

.ai-table .ant-table-tbody tr td{
  padding: 5px !important;
}
