.print-wrapper {
    border: 0px;
    border-radius: 0;
    background: #ffffff;
    .box-body {
        padding: 0;
        .invoice-wrapper {
            .main-heading {
                font-size: 1.4rem;
                margin-bottom: 0;
                line-height: normal;
            }
            .sub-heading {
                font-size: 1.1rem;
                font-weight: bold;
                margin-bottom: 0.8rem;
            }
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
                td,
                th {
                    border: 0px solid #dddddd;
                    text-align: left;
                    padding: 8px;
                }
                tr {
                    &.ant-table-row-level-0 {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }
            }
            .signature {
                display: flex;
                padding-top: 3rem;
                p {
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 1rem;
                    width: 20rem;
                }
                .left {
                    display: flex;
                    justify-content: flex-start;
                    width: 50%;
                }
                .right {
                    display: flex;
                    justify-content: flex-end;
                    width: 50%;
                }
            }
            .view-list {
                .list-unstyled > li {
                    font-weight: bold;
                    strong {
                        font-size: 15px;
                        font-weight: 300 !important;
                    }
                    &.null-value {
                        font-weight: normal;
                    }
                }
            }
            .laytime-activities {
                tbody {
                    tr {
                        td {
                            padding: 0px 8px;
                        }
                    }
                }
            }
            .laytimereport {
                tr {
                    &:nth-child(even) {
                        background-color: #f9f9f9;
                    }
                }
            }
            .invoice-container {
                & > .invoice-inner {
                    .title {
                        font-size: 7.5rem;
                        font-weight: 600;
                        line-height: 5rem;
                        font-family: cursive;
                        color: #2a4859;
                        position: relative;
                        &:after {
                            content: "";
                            position: absolute;
                            left: auto;
                            top: auto;
                            width: 15px;
                            height: 15px;
                         
                            border-radius: 50%;
                            -moz-border-radius: 50%;
                            -webkit-border-radius: 50%;
                            right: -10px;
                            bottom: 2rem;
                        }
                    }
                    .sub-title {
                        color: #568c33;
                        font-weight: bold;
                        font-size: 1rem;
                        line-height: normal;
                    }
                    .custom-table {
                        & > .table {
                            th,
                            td {
                                &:first-child {
                                    padding-left: 0;
                                    width: 80%;
                                }
                            }
                            th,
                            td {
                                &:nth-child(2),
                                &:nth-child(3) {
                                    width: 10%;
                                }
                            }
                            td {
                                &:last-child {
                                    padding-right: 0;
                                }
                            }
                            tbody {
                                .content {
                                    th,
                                    td {
                                        font-weight: normal;
                                    }
                                }
                                .amount {
                                    border-top: 1px solid #ddd;
                                    border-bottom: 1px solid #ddd;
                                    /*border-top-width: 1px;
                                    border-bottom-width: 1px;*/
                                    border-left: 0px;
                                    border-right: 0px;
                                    padding: 3px 0px;
                                }
                                .no-padding {
                                    padding: 0 0;
                                }
                                .no-top-padding {
                                    padding-top: 0;
                                }
                                .no-bottom-padding {
                                    padding-bottom: 0;
                                }
                                .no-padding-right {
                                    padding-right: 0;
                                }
                                .no-padding-left {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                    .spacer {
                        padding: 0.5rem;
                    }
                }
                &.hire {
                    .view-list {
                        .sub-heading {
                            &.text-right {
                                font-size: 1.5rem;
                                margin-bottom: 0.5rem;
                                line-height: normal;
                            }
                        }
                        .wrap-data {
                            display: flex;
                            align-items: center;
                            border: 3px solid #ccc6c6;
                            padding: 1.5rem 3rem 1.5rem 3rem;
                            .text-left {
                                .list-unstyled {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .bg-colors-box{
        background: #f7f7f7;
        margin-top: 12px;
        padding: 12px 8px;
        border: 1px solid #eaeaea;
    }
    .custum-table{
        table{
            tr{
                th:last-child, td:last-child{
                    text-align: right;
                }
            }
        }
    }
}

@media print {
    @page {
        size: auto; // auto, landscape, portrait options are available
        margin: 8mm;
    }

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        background-color: #ffffff;
    }

    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .hide-on-print {
        display: none;
    }
}
