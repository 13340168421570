.toolbar {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  color: #696868;
  font-size: 16px;
  width: 45%;
  padding: 5px;
  align-items: center;
  border-radius: 39px;
  background-color: white;
  margin: 10px 5px;
  box-shadow: 5px 10px 55px 0 rgba(0, 0, 0, 0.21);
  font-weight: 500;
}

.toolbar > li {
  cursor: pointer;
}

.sidetoolbar {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
  list-style: none;
  margin-top: 10px;
  border-radius: 39px;
  background-color: white;
  box-shadow: 5px 10px 26px 0 rgba(0, 0, 0, 0.21);
  width: 40px;
  position: absolute;
  top: 0;
  justify-content: space-around;
  align-items: center;
  right: 5px;
  padding: 5px 0;
}

.sidetoolbar-img {
  width: 20px;
  height: 20px;
}

button.show-port-distance-btn {
  position: absolute;
  top: -1.7rem;
  right: 0px;
  background: #2f3349;
  border: 1px solid navy;
  padding: 5px 11px;
  border-radius: 30px 0px 0px 30px;
  border-right: 0;
  color: white;
  cursor: pointer;
}
