.compose-drawer .ant-drawer-body {
  padding: 0 !important;
  border: none;
  box-sizing: border-box;
  box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08),
    -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.composeWrapper {
  position: relative;
}

.compose-drawer {
  border: none;
  transition: right 0.3s ease-in-out;
}

/* Header of the popup */
.compose-header {
  padding: 10px 15px;
  background-color: #003e78;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.compose-header .title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.compose-header .close-btn {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
}

/* Body of the popup */
.compose-body {
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.compose-body input,
.compose-body textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.compose-body .compose_input {
  border: 0;
  background: transparent;
}

.compose-body .compose_input:focus,
.compose-body .compose_input:hover {
  outline: none;
  border: 0px !important;
  box-shadow: none;
}

.compose-body .ant-select-selector {
  border: 0 !important;
}

.compose-body .ant-select-focused .ant-select-selector {
  outline: none;
  border: 0px !important;
  box-shadow: none !important;
}

/* .custom-select .ant-select-selector .ant-select-selection-search {
    display: none;
  } */

.custom-select .ant-select-arrow {
  display: none;
}

/* .compose-body .input_to {
    padding: 0;
  } */

/* .compose-input-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #d9d9d9;
    padding: 4px;
    border-radius: 4px;
  }
  
  .email-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-right: 8px;
  } */

.compose-body textarea {
  flex: 1;
  resize: none;
}

/* Footer of the popup */
.compose-footer {
  padding: 10px 15px;
  background-color: #f1f3f4;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  /* position: absolute; */
  bottom: 0;
}

.compose-footer .btn {
  background-color: #003e78;
  color: #fff;
  border: none;
  padding: 10px 25px;
  line-height: 1;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.compose-footer .btn:hover {
  background-color: #1558b3;
}

.compose-footer .ant-upload {
  font-size: 18px;
  margin-left: 10px;
}

/* .compose-drawer .compose-body{
    margin-bottom: 30px;
  } */
.ant-modal-content .compose-body,
.compose-drawer .compose-body {
  /* padding-bottom: 50px; */
}

.ql-toolbar .ql-emoji svg {
  display: none;
}

.ql-toolbar .ql-emoji::before {
  content: "\263A";
  font-size: 22px;
  font-weight: bolder;
  line-height: 0;
  display: inline-block;
  vertical-align: baseline;
}

ql-toolbar.ql-snow .ql-formats {
  margin-right: 10px !important;
}

.compose-popup {
  height: 100%;
}

.composeModal .ant-modal-content,
.composeModal .ant-modal-content .ant-modal-body {
  padding: 0;
}

.composeModal {
  z-index: 1100 !important;
}

.ql-container.ql-snow {
  /* min-height: 331px; */
  /* max-height: 250px; */
  overflow-y: auto;
}

.react-ouillparent {
  position: relative;
}

.custom-quill .ql-toolbar {
  border-right: none !important;
  border-left: none !important;
}

.custom-quill .ql-container {
  border-right: none !important;
  border-left: none !important;
  border-bottom: none !important;
}

.custom-quill .ql-editor {
  min-height: 10rem;
  /* Ensure the editor still maintains the desired min-height */
  margin-bottom: 10px;
  /* Ensure the editor still maintains the desired margin-bottom */
}

/* .ql-container.ql-snow */

/* width */
.ql-container.ql-snow::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.ql-container.ql-snow::-webkit-scrollbar-track {
  background: #d5d5d5;
}

/* Handle */
.ql-container.ql-snow::-webkit-scrollbar-thumb {
  background: #85878a;
}

/* Handle on hover */
/* .ql-container.ql-snow::-webkit-scrollbar-thumb:hover {
  background: #55a9c0;
} */

.ql-tooltip {
  left: 0 !important;
}

.custom-submenu ul {
  max-height: 300px;
  /* Set the maximum height as needed */
  overflow-y: auto;
  min-width: 125px;
}

.custom-submenu ul::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom-submenu ul::-webkit-scrollbar-track {
  background: #d5d5d5;
}

/* Handle */
.custom-submenu ul::-webkit-scrollbar-thumb {
  background: #85878a;
}

.swal-custom-container {
  z-index: 2000 !important;
}

.rewrite_oceannai {
  cursor: pointer;
  position: absolute;
  bottom: 60px;
  right: 45px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  color: #003e78;
  background-color: #f2f5f5;
  padding: 4px 7px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.rewrite_oceannai:hover {
  background-color: #e6e7e9;
}

.div_button {
  cursor: pointer;
  padding: 4px 7px;
  color: #003e78;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.div_button:hover {
  background-color: #e6e7e9;
}

.auto-reply {
  display: flex;
  margin-left: 10px;
  border-radius: 10px;
  padding: 4px 7px;
  font-weight: bold;
  font-style: italic;
  color: #003e78;
}

.auto-reply:hover {
  background-color: #e6e7e9;
  cursor: pointer;
}

.custom-quill-reply .ql-editor {
  min-height: 20rem;

  margin-bottom: 10px;
  margin-top: var(--quill-margin-top, 5%);
}

.image-menu {
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  width: 65%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.image-menu input {
  width: 100%;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5%;
}

.cancleButton-insert-image {
  background: #fff !important;
  color: #003e78 !important;
}

/* .image-menu button {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
} */