/* tooltip.css */
.custom-tooltip {
  background-color: #f5f5f9;
  color: rgba(0, 0, 0, 0.87);
  max-width: 220px;
  font-size: 12px;
  /* border: 1px solid #dadde9; */
  padding: 0px !important;
}
.custom-tooltip2 {
  background-color: #12406a;
  color: rgba(0, 0, 0, 0.87);
  /* max-width: 220px; */
  font-size: 12px;
  /* border: 1px solid #dadde9; */
  padding: 0px !important;
}

.custom-tooltip h2 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

/* .custom-tooltip .ant-card-meta-title{text-align: center;} */
.custom-tooltip .ant-tooltip-arrow:before, .custom-tooltip .ant-tooltip-arrow:after{display: none;}
.custom-tooltip .ant-card-body{padding:0px; overflow: hidden;}
.custom-tooltip .ant-tooltip-inner{padding:0px; border: none; background-color: transparent;}
.custom-tooltip .ant-card-meta-description{line-height: 1.5; padding: 7px 10px 15px 10px; border-top: 1px solid #222; color:#222; }
.custom-tooltip p {margin: 0;}
/* when using popover */
.custom-tooltip .toolTipCard {background-color:rgb(239, 239, 239, 0.3);}
.custom-tooltip .ant-card-meta-title .toolTipTitle{font-size: 0.9rem; color: #fff;}
.custom-tooltip .ant-popover-inner {padding: 0; }
.custom-tooltip .ant-card-meta-title{height: auto !important; padding: 6px 10px 3px; background-color: #003e78; margin-bottom: 0px !important;}
.custom-tooltip .ant-image {height: auto !important;}
.custom-tooltip .ant-image-img {height: auto !important;}
/* .custom-tooltip .ant-card-meta-detail{padding: 5px 15px 15px 15px} */


body .toolTipBtn{color: #034079; padding: 0.6rem 4px; text-align: center; border: 1px solid #034079; background-color: #fff; transform: none; border-radius: 2rem; min-width: 104px; line-height: 1; margin-bottom: 10px;}
body .toolTipBtn:hover{ background-color: #034079; border: 1px solid #033361;  color: #fff;}