.mapboxgl-popup-content{
    /* padding:10%; */
    /* width: 300px; */
    background: rgba(255,255,255, 0.9);
}
.map_p{
    font-size: 14px;
    border-bottom: 1px solid rgba(210, 210, 210, 0.4)
}

 /* map css from live vessel */
 .mapboxgl-popup-content {
    /* padding: 10%; */
    /* width: 300px; */
    background: rgba(255, 255, 255, 0.9);
}

.map_p {
    font-size: 14px;
    border-bottom: 1px solid rgba(210, 210, 210, 0.4)
}

.filter-search-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    padding-top: 32px;
    background: #fff;
    max-width: 520px;
    box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    min-width: 350px;
    width: 100%;

    position: relative;
    box-shadow: none;
    border-right: 1px solid;
    border-radius: 0;
    max-height: 100%;
}

.filter-search-list.filter-local-data {
    width: auto;
}

.filter-search-list .live-data-loader {
    position: absolute;
    top: 30px;
    left: 15px;
    padding-top: 150px;
    z-index: 10;
    background-color: #0000001c;
    display: flex;
    justify-self: center;
    width: calc(100% - 30px);
    height: calc(100% - 45px);
    border-radius: 10px;
}
.filter-search-list .live-data-loader > div { width: 100%; }
.filter-search-list .close-icon-wrapper {
    position: absolute;
    left: -16px;
    top: 4px;
    -webkit-user-select: none;
    user-select: none;
    z-index: 1;
    transition: all .3s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    height: 30px;
    width: 30px;
    background: #fff;
    box-shadow: 3px 0px 14px -7px rgb(0 0 0 / 50%);
}
.filter-search-list .close-icon-wrapper:hover {
    transform: scale(1.2);
}

.close-icon-wrapper1{
    position: absolute;
  
    top: 35px;
    -webkit-user-select: none;
    user-select: none;
    z-index: 1;
    transition: all .3s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    height: 30px;
    width: 30px;
    background: #fff;
    box-shadow: 3px 0px 14px -7px rgb(0 0 0 / 50%);
    right: 30px
}


.close-icon-wrapper1:hover{
    transform: scale(1.2);
}





.filter-search-list>.close-icon>input {
    color: black;
    border: 1px solid #9f9f9f;
    outline: none;
    flex-grow: 1;
    margin: 0.5rem 0;
    padding: 0.5rem;
    padding-right: 28px;
    font-size: small;
    height: 36px;
    border-radius: 8px;
    width: 100%;
    transition: all .1s linear;
}

.filter-search-list>.close-icon>input:focus {
    outline: 1px solid;
}

.filter-search-list .list-data {
    border: 1px solid #9f9f9f;
    padding: 0.75rem 1rem;
    transition: all 1s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 25vw;
    height: 100%;
    border-radius: 10px;
}
.filter-search-list .data-message {
    text-align: center;
    font-weight: bold;
}

.filter-search-list .list-data .list-item,
.filter-search-list .list-data .selected-list-item {
    color: #000;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}

.filter-search-list .list-data .list-item .middle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 2;
    color: #002e42;
}

.filter-search-list .list-data .list-item .middle-wrapper .vessel-name {
    text-align: center;
    font-weight: 1000;
    text-transform: uppercase;
}

.filter-search-list .list-data .list-item .last-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    color: #488a99;
    flex-grow: 1;
}

.filter-search-list .list-data .list-item .middle-wrapper .middle-wrapper-discription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
}

.filter-search-list .list-data .list-item img {
    height: 5rem;
}

.filter-search-list .list-data .list-item:hover {
    background-color: #fff;
}

.selected-data-container {
    font-weight: 1000;
    text-transform: uppercase;
    color: #fff;
}

.first-div,
.second-div {
    display: flex;
    justify-content: space-between;
    padding: 2px;
}



.selected-data-name {
    text-transform: uppercase;
    font-weight: 1000;
}

.selected-data-name1 {
    text-transform: uppercase;
    font-weight: 500;
}
.first-div .selected-vessel-name {
    font-size: 1.2rem;
    color: #0ba70b;
}

.heading-name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #efefef;
    padding: 1rem;
    position: relative;
}

.heading-name-container>img {
    height: 26vh;
}

.heading-icon-list {
    position: absolute;
    display: flex;
    padding: 0.5rem 1rem;
    gap: 1rem;
    bottom: 0;
    left: 0;
    background: linear-gradient(360deg, rgba(0, 13, 31, .5) 0%, rgba(26, 71, 91, 0) 100%);
    width: 100%;
}

.heading-icon-list .service-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.first-div .heading-logo-container {
    align-items: center;
    display: flex;
    gap: 0.25rem;
}

.first-div .heading-logo-container img {
    height: 1.2rem;
}

.service-logo-container img,
.service-logo-container .icon-data {
    height: 3vh;
    font-weight: 1000;
}

.list-items-services .image-wrapper>img {
    height: 1vh;
}

.first-div>.selected-vessel-id {
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected-next-port-name {
    font-size: 1.2rem;
    color: #d10519;
}

/* list 1 */
.transition,
p,
ul.ul-lists-services li i:before,
ul.ul-lists-services li i:after {
    transition: all 0.25s ease-in-out;
}

.flipIn,
h1,
ul.ul-lists-services li {
    animation: flipdown 0.5s ease both;
}

.no-select,
h2.service-name {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

h2.service-name {
    font-size: 1rem;
    font-weight: 1000;
}

p.service-content {
    color: rgba(48, 69, 92, 0.8);
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    max-height: 800px;
    opacity: 1;
    transform: translate(0, 0);
    margin-top: 14px;
    z-index: 2;
}

ul.ul-lists-services {
    list-style: none;
    perspective: 900;
    padding: 0;
    margin: 0;
}

ul.ul-lists-services li {
    position: relative;
    padding: 0;
    margin: 0;
    padding-bottom: 4px;
    padding-top: 18px;
    border-top: 1px dotted #000;
}

ul.ul-lists-services li:nth-of-type(1) {
    animation-delay: 0.5s;
}

ul.ul-lists-services li:nth-of-type(2) {
    animation-delay: 0.75s;
}

ul.ul-lists-services li:nth-of-type(3) {
    animation-delay: 1.0s;
}

ul.ul-lists-services li:last-of-type {
    padding-bottom: 0;
}

ul.ul-lists-services li i {
    position: absolute;
    transform: translate(-6px, 0);
    margin-top: 16px;
    right: 0;
}

ul.ul-lists-services li i:before,
ul.ul-lists-services li i:after {
    content: "";
    position: absolute;
    /* background-color: #000; */
    width: 3px;
    height: 9px;
}

ul.ul-lists-services li>.service-logo-container {
    display: flex;
    gap: 0.25rem;
}

ul.ul-lists-services li>.service-logo-container>img {
    height: 1.2rem;
}

/* ul.ul-lists-services li i:before {
    transform: translate(-2px, 0) rotate(45deg);
}

ul.ul-lists-services li i:after {
    transform: translate(2px, 0) rotate(-45deg);
}

ul.ul-lists-services li input[type=checkbox] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
}

ul.ul-lists-services li input[type=checkbox]:checked~p {
    margin-top: 0;
    max-height: 0;
    opacity: 0;
    transform: translate(0, 50%);
}

ul.ul-lists-services li input[type=checkbox]:checked~i:before {
    transform: translate(2px, 0) rotate(45deg);
}

ul.ul-lists-services li input[type=checkbox]:checked~i:after {
    transform: translate(-2px, 0) rotate(-45deg);
} */

@keyframes flipdown {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }

    5% {
        opacity: 1;
    }

    80% {
        transform: rotateX(8deg);
    }

    83% {
        transform: rotateX(6deg);
    }

    92% {
        transform: rotateX(-3deg);
    }

    100% {
        transform-origin: top center;
        transform: rotateX(0deg);
    }
}

/* filter section */

.filter-boxes {
    /* margin: auto; */
    padding: 1.2rem;
}

/*Checkboxes styles*/
.filter-boxes>input[type="checkbox"] {
    display: none;
}

.filter-boxes input[type="checkbox"]+label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    font: 14px/20px 'Open Sans', Arial, sans-serif;
    color: #000;
    cursor: pointer;
}

.filter-boxes input[type="checkbox"]+label:last-child {
    margin-bottom: 0;
}

.filter-boxes input[type="checkbox"]+label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #6cc0e5;
    position: absolute;
    left: 0;
    top: 0;
    opacity: .6;
    -webkit-transition: all .12s, border-color .08s;
    transition: all .12s, border-color .08s;
}

.filter-boxes input[type="checkbox"]:checked+label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.vessel-filter {
    position: fixed;
    right: 0px;
    top: 60px;
    background: transparent;
    height: calc(40vh - 60px);
    /* overflow-y: auto; */
    z-index: 10;
    /* box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.5); */
    display: flex;
    flex-wrap: nowrap;
}
.vessel-filter > ul.vessel-filter-actions {
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: #fff;
    box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.5);
}
.vessel-filter > ul.vessel-filter-actions li {
    padding: 6px 15px;
    font-size: 20px;
    transition: all .2s linear;
    user-select: none;
    cursor: pointer;
}
.vessel-filter > ul.vessel-filter-actions li.active {
    background-color: #dbae58;
    color: #fff;
}
.vessel-filter > ul.vessel-filter-actions li:hover {
    transform: scale(1.2);
}

@media only screen and (max-width: 1200px) {
    .filter-search-list {
        max-width: 450px;
    }
}


.map-box-wrapper .mapboxgl-popup-content {
    /* min-width: 500px; */
    width:min-content !important;
    background: rgba(97, 97, 97, 0.9);
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
    /* padding-right: 17px; */
    /* border: 2px solid rgb(2, 108, 2); */
    border-radius: 6px;
    padding: 8px;
}

.map-box-wrapper .mapboxgl-popup-content .mapboxgl-popup-close-button {
    height: 15px;
    width: 15px;
    top: -1px;
    right: 0;
}
/* .map-box-wrapper .mapboxgl-popup-content .live-popup {
    min-width: 600px;
} */
.map-box-wrapper .mapboxgl-popup-content .hover-content-map-item > div + div {
    border-left: 2px solid rgba(210, 210, 210, 0.4)
}

/* .hover-content-map-item {
    display: flex;
} */

/* .hover-content-map-item > div {
    width: 50%;
} */

.map-box-wrapper .map_p {
    width: 100%;
    font-size: 12px;
    color: #fff;
    /* padding: 5px; */
    /* border-bottom: 2px solid rgba(210, 210, 210, 0.4); */
    transition: all .2s ease-in-out;
}
.map-box-wrapper .live-popup span.green,
.map-box-wrapper .map_p span {
    /* color: #0ba70b; */
    font-weight:700;
}
.map-box-wrapper .live-popup span.red,
.map-box-wrapper .map_p span.red {
    /* color: tomato; */
    text-transform:capitalize;
}

.map-box-wrapper .map_p:hover {
    background-color: rgb(230, 230, 230);
}

.mapboxgl-canvas-container {
    height: 100vh;
}

.marker-hover-popup .mapboxgl-popup-content {
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(2, 108, 2);
    position: relative;
    padding: 0;
    justify-content: center;
    font-size: 18px;
    padding: 4px;
}

.map-box-wrapper .mapboxgl-popup-content .live-popup-wp {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color);
}
.map-box-wrapper .mapboxgl-popup-content .live-popup-wp .title-block {
    width: 49%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.map-box-wrapper .mapboxgl-popup-content .live-popup-wp .title-block + .title-block {
    border-left: 2px solid #fff;
}
.map-box-wrapper .mapboxgl-popup-content .live-popup-wp .title-block > div {
    width: 49%;
    padding: 4px 8px;
}
.map-box-wrapper .mapboxgl-popup-content .live-popup .port-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.map-box-wrapper .mapboxgl-popup-content .live-popup .port-detail > div {
    width: 49%;
}
.map-box-wrapper .mapboxgl-popup-content .cii-block .cii-title {
    background-color: var(--primary-color);
    color: #fff;
    padding: 5px 8px;
}
.map-box-wrapper .mapboxgl-popup-content .cii-block .cii-item {
    display: flex;
    border-bottom: 2px solid #ccc;
}
.map-box-wrapper .mapboxgl-popup-content .cii-block .cii-item .cii-col + .cii-col {
    border-left: 2px solid #ccc;
}
.map-box-wrapper .mapboxgl-popup-content .cii-block .cii-item .cii-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 49%;
}
.map-box-wrapper .mapboxgl-popup-content .cii-block .cii-item b {
    padding-left: 5px;
}
.map-box-wrapper .mapboxgl-popup-content .cii-block .cii-item .fa-lock {
    padding: 0 5px;
}
.map-box-wrapper .gray-bg {
    background-color: rgb(228, 228, 228);
}

.mapboxgl-popup-content > .bunker-price  {
    width: fit-content;
}


.mapboxgl-popup-content .fuel-type {
    display: flex;
    justify-content: space-between;
}
.mapboxgl-popup-content .fuel-type .fuel {
   width: 50px;
}
.mapboxgl-popup-content .fuel-price {
    min-width: 300px;
    display: flex;
    justify-content: space-between;
}
.mapboxgl-popup-content .fuel-price .price {
    width: 50px;
}

.mapboxgl-popup-content {
    min-width: 200px;
}

.mapboxgl-popup-content .port-name {
    /* margin-left: 14px; */
    text-decoration: underline;
}
.mapboxgl-popup-content .port-name > span {
    margin-left: 16px;
}
